<template>
    <div class="schedulSeting">
        <div class="tabs">
            <ul>
                <li class="active">排班设置</li>
                <li  @click="_route">排班管理</li>
            </ul>
        </div>
        <div class="content-warp">
            <div class="flex">
                <div>排班设置</div>
                <a-button v-if="canEdit==1" type="primary" @click="_action('add')">添加班次</a-button>
            </div>
            <div class="search" style="display: block;margin-top: 20px">
                <span class="search-title">班级名称</span>
                <a-input v-model="SearchName" class="search-style" placeholder="最多10个字符" />
                <a-button class="search-style" @click="_clear">重置</a-button>
                <a-button class="search-style" type="primary" @click="_search">查询</a-button>
            </div>
            <a-table :pagination="false" bordered v-if="tableData"
                     :columns="columns"
                     :data-source="tableData"

            >
                <div slot="Name" slot-scope="text,record">
                    <a-tag :color="record.Color">
                        {{text}}
                    </a-tag>
                </div>
                <div slot="StartTime" slot-scope="text,record">
                    {{ record.StartTime }}-{{record.EndTime}}
                </div>
                <div slot="Position" slot-scope="text,record">
                    {{ record.CreateUserName }}({{record.Position}})
                </div>
                <div slot="Id" slot-scope="text,record">
                    <i v-if="canEdit==1" class="iconfont icon-bianji icon-btn" style="font-size: 20px" @click.stop='_action("save",record)'></i>&nbsp;&nbsp;
                    <a-popconfirm title="您确定要删除吗?" @confirm="_del(text)" okText="确定" cancelText="取消">
                        <i v-if="canEdit==1" class="iconfont icon-shanchu- icon-btn"></i>
                    </a-popconfirm>
                </div>
            </a-table>
            <a-pagination v-if="count" style="margin-top: 35px;text-align: right;" @change="ChangePage" :pageSize="pagesize" :total="count"  show-less-items />
        </div>


        <a-drawer
                :title="Id?'编辑班次':'新增班次'"
                width="520"
                :closable="false"
                :visible="visible"
                @close="onClose"
        >
            <div v-if="visible">
                <div class="group">
                    <div class="group-label">班次名称</div>
                    <a-input v-model="Name" class="group-item" placeholder="最多10个字符" />

                </div>
                <div class="group" style="color:rgba(25,31,37,1);">打卡时段</div>
                <div class="group">
                    <div class="group-label">上班</div>
                    <a-time-picker v-if="StartTime"  :defaultValue="moment(StartTime, 'HH:mm')"  format="HH:mm" @change="onChangeStart" />
                    <a-time-picker v-else format="HH:mm" @change="onChangeStart" />
                </div>
                <div class="group">
                    <div class="group-label">下班</div>
                    <a-time-picker v-if="EndTime" :default-value="moment(EndTime, 'HH:mm')" format="HH:mm"  @change="onChangeEnd" />
                    <a-time-picker v-else  format="HH:mm"  @change="onChangeEnd" />
                </div>
                <div class="group">
                    <div class="group-label">备注</div>
                    <a-textarea v-model="Remark" class="group-item" :rows="4" />
                </div>
                <div class="group">
                    <div class="group-label">标注</div>
                    <div style="position: relative" class="group-item">
                        <div class="color_con"  :style="'border-radius:5px;height: 32px;background:'+Color"></div>
                        <div style="overflow: hidden">
                            <sketch-picker v-model="Color"  @input="updateValue"></sketch-picker>
                        </div>
                    </div>
                </div>
            </div>


            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
            >
                <a-button style="marginRight: 8px" @click="onClose">
                    取消
                </a-button>
                <a-button @click="_toAction()" type="primary" >
                    确认
                </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { Sketch } from 'vue-color'
    export default {
        name: "schedulSeting",
        data(){
            return{
                userinfo:JSON.parse(sessionStorage.getItem("userInfo")),
                visible: false,
                Name:'',
                StartTime:'',
                EndTime:'',
                Remark:'',
                Id:'',
                pageNo:1,//页码数
                pagesize:20,
                tableData:'',
                count:'',
                columns:[
                    {
                        title: '班次名称',
                        dataIndex: 'Name',
                        scopedSlots: { customRender: 'Name' },
                    },
                    {
                        title: '考勤时间',
                        dataIndex: 'StartTime',
                        scopedSlots: { customRender: 'StartTime' },
                    },
                    {
                        title: '备注',
                        dataIndex: 'Remark',
                    },
                    {
                        title: '添加人',
                        dataIndex: 'Position',
                        scopedSlots: { customRender: 'Position' },
                    },
                    // {
                    //     title: '操作',
                    //     dataIndex: 'Id',
                    //     scopedSlots: { customRender: 'Id' },
                    // },
                ],
                SearchName:'',
                Color:'#F82626',
                canEdit:0,
            }
        },
        components: {
            'sketch-picker': Sketch
        },
        created(){
            this._loadData();
        },
        methods:{
            moment,
            _loadData(){
                let self = this;
                this.$axios.get(1105,{
                    pagesize:self.pagesize,
                    pageNo:self.pageNo,
                    Name:self.SearchName,
                },res=>{
                    
                    self.canEdit = res.data.canEdit;
                   let arr = {
                       title: '操作',
                       dataIndex: 'Id',
                       scopedSlots: { customRender: 'Id' },
                   };

                   if(res.data.canEdit ==1){
                        self.columns.push(arr);
                   }
                   if(res.data.code==1){
                    self.tableData = res.data.data;
                    self.count= res.data.total;

                   }else{
                       this.$message.error(res.data.msg)
                   }
                })
            },
            ChangePage(value){
                this.pageNo = value;
                this._loadData();
            },
            _search(){
                this._loadData();
            },
            _clear(){
                this.SearchName ='';
                this.pagesize = '';
                this._loadData();
            },
            _del(id){
                let self = this;
                this.$axios.get(1106,{
                    Id:id,
                    UserId:self.userinfo.uid,
                },res=>{
                    if(res.data.code==1){
                        this.$message.success(res.data.msg);
                        self._loadData();
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            _toAction(){
                let self = this;
                if(!this.Name){
                    this.$message.error('请输入班次名称');
                    return false;
                }
                if(!this.StartTime){
                    this.$message.error('请输入上班时间');
                    return false;
                }
                if(!this.EndTime){
                    this.$message.error('请输入下班时间');
                    return false;
                }
                let formatDate1 = this.StartTime.split(':');
                let intStartTime = formatDate1[0]*3600+formatDate1[1]*60;
                let formatDate2 = this.EndTime.split(':');
                let intStopTime   = formatDate2[0]*3600+formatDate1[1]*60;
                if(intStartTime>=intStopTime){
                    this.$message.error('上班时间不能大于下班时间');
                    return false;
                };
                this.$axios.post(1104,{
                    Name:self.Name,
                    StartTime:self.StartTime,
                    EndTime:self.EndTime,
                    Id:self.Id,
                    Remark:self.Remark,
                    UserId:self.userinfo.uid,
                    Color:self.Color,
                },res=>{
                    console.log(res);
                    if(res.data.code==1){
                        self.$message.success(res.data.msg);
                        self.onClose();
                        self._loadData();
                    }else{
                        self.$message.error(res.data.msg);
                    }
                })
            },
            onChangeStart(time, timeString) {
                this.StartTime = timeString;
            },
            onChangeEnd(time, timeString) {
                this.EndTime = timeString;
            },
            _action(type,value){
                if(type=='save'){
                    this.Name = value.Name;
                    //this.$set(value,'StartTime',value.StartTime);
                    this.StartTime = value.StartTime;
                    //console.log(this.StartTime);
                    this.EndTime = value.EndTime;
                    this.Id = value.Id;
                    this.Remark = value.Remark;
                    this.Color = value.Color;
                    this.$forceUpdate();
                }
                this.visible = true;
            },
            updateValue (val) {
                this.Color = val.hex;
            },
            onClose(){
                this.Name ='';
                this.EndTime ='';
                this.StartTime ='';
                this.Remark ='';
                this.Id ='';
                this.Color ='';
                this.visible = false;
            },
            _route(){
                this.$router.push({path: '/scheduling'})
            },
        }
    }
</script>

<style scoped lang="less">
    .schedulSeting{
        /*background: #ffffff;*/
        margin-top: 15px;
        min-height: 800px;
        /*padding: 15px;*/
    }
    .search{
        margin-bottom: 20px;
        .search-style{
            display: inline-block;
            width: 120px;
            margin-right: 15px;
            margin-bottom: 10px;
        }
        .search-title{
            margin-right: 5px;
            
        }
        /*.search-group{*/
        /*display: inline-block;*/
        /*}*/
    }
    .tabs{
        border-bottom: 1px solid #CCD1DC;
        border-left: 1px solid #CCD1DC;
        border-top-left-radius: 5px;
        /*background: #ffffff;*/
        ul{
            li{
                display: inline-block;
                width: 85px;
                padding: 6px 0;
                text-align: center;
                border: 1px solid #CCD1DC;
                border-bottom: none;
                cursor: pointer;
                border-left: 0;
                &:first-child{
                    border-top-left-radius: 5px;
                }
                &:last-child{
                    border-top-left-radius: 0;
                    border-top-right-radius: 5px;
                }
            }
            .active{
                background: #1890FF;
                color: #fff;
                border: 1px solid #1890FF;
            }
        }
    }
    .content-warp{
        margin-top: 20px;
        padding: 15px;
        background: #ffffff;
        border-radius: 8px;
        min-height: 800px;
    }
    .group{
        display: flex;
        margin-bottom: 20px;
        .group-label{
            width: 80px;
            height: 32px;
            line-height: 32px;
            font-size:13px;
            color:rgba(102,102,102,1);
        }
        .group-item{
            flex:1;
        }
    }

</style>